import prism from "./prism";

export default {
  prism,

  primary: "#FFFFFF",
  secondary: "#EDEBEB",
  grey: "rgba(255, 255, 255, 0.5)",
  background: "#090A0B",
  backgroundModal: "rgba(209, 232, 235, 0.98)",
  cleanBackground: "#FFFFFF",
  accent: "#F5495E",
  hover: "rgba(0, 0, 0, 0.07)",
  gradient: "linear-gradient(180deg, rgba(9, 10, 11, 0) 0%, rgba(9, 10, 11, 0.92) 67.81%, #090A0B 100%)",
  gradientText: "linear-gradient(92.72deg, #FFFFFF 4.64%, #F2B5B0 87.93%)",
  gradientFill: "none",
  articleText: "#E5E4E4",
  track: "rgba(8, 8, 11, 0.3)",
  progress: "#000",
  card: "#111111",
  error: "#EE565B",
  success: "#46B17B",
  errorBackground: "rgba(238, 86, 91, 0.1)",
  horizontalRule: "rgba(8, 8, 11, 0.15)",
  inputBackground: "rgba(0, 0, 0, 0.05)",
  textTitle: "#111111",
  neumorphismShadown: "none",
  neumorphismBorder: "none",
  heroBackground: `url("/site-hero.jpg")`,
  heroArticleBackgroundDesktop: `url("/image_hero_article.jpg")`,
  heroArticleBackgroundMobile: `url("/image_hero_article_mobile.jpg")`,
  borderRadius: "8px",
  borderRadiusM: "8px",
  borderThick: "4px solid rgba(44, 44, 44, 0.0)",
  backgroundDark: "#111314",
  modes: {
    dark: {
      primary: "#27292D",
      secondary: "#5B5C5E",
      grey: "#83878E",
      accent: "#F5495E",
      background: "#F0F0F4",
      backgroundModal: "rgba(17, 18, 22, 0.95)",
      cleanBackground: "#111216",
      hover: "rgba(255, 255, 255, 0.07)",
      gradient:
      "linear-gradient(180deg, rgba(240, 240, 244, 0) 0%, rgba(240, 240, 244, 0.92) 67.81%, #F0F0F4 100%)",
      gradientText: "linear-gradient(92.72deg, #27292D 4.64%, #27292D 87.93%)",
      gradientFill: "linear-gradient(142.01deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #F0F0F4",
      articleText: "#27292D",
      track: "rgba(255, 255, 255, 0.3)",
      progress: "#fff",
      card: "#1D2128",
      error: "#EE565B",
      success: "#46B17B",
      errorBackground: "rgba(238, 86, 91, 0.1)",
      horizontalRule: "rgba(255, 255, 255, 0.15)",
      inputBackground: "rgba(255, 255, 255, 0.07)",
      textTitle: "#fff",
      neumorphismShadown: "20px 20px 48px 0 rgba(166,171,189,0.84)",
      neumorphismBorder: "4px solid rgba(255, 255, 255, 0.16)",
      // neumorphismShadown: "20px 20px 40px 0 rgba(166,171,189,0.64), inset 0 0 0 4px #27292D",
      heroBackground: "none",
      heroArticleBackgroundDesktop: "none",
      heroArticleBackgroundMobile: "none",
      borderRadius: "40px",
      borderRadiusM: "24px",
      borderThick: "4px solid #2C2C2C",
      backgroundDark: "#FFFFFF",
    },
  },
};
