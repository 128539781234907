// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---narative-gatsby-theme-novela-src-pages-404-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/pages/404.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-pages-404-tsx" */),
  "component---narative-gatsby-theme-novela-src-pages-about-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/pages/about.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-pages-about-tsx" */),
  "component---narative-gatsby-theme-novela-src-pages-awesome-files-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/pages/awesome-files.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-pages-awesome-files-tsx" */),
  "component---narative-gatsby-theme-novela-src-pages-awesome-plugins-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/pages/awesome-plugins.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-pages-awesome-plugins-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-category-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/category.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-category-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-featured-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/featured.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-featured-template-tsx" */)
}

